/* You can add global styles to this file, and also import other style files */
@import "bootstrap/bootstrap";
@import "spinner";
@import "utils";
@import "rtl";
@import "responsive";

@media print {
    .breadcrumb {
        display: none !important;
    }
}



/* Custom styles */

.formContent{
    display: block;
    width: 100%;
    max-width: 450px;
    margin: 10% auto;

}
.searchContainer{
    display: block;
    width: 100%;
    border: 1px solid #333;
    margin-bottom: 15px;
    margin-top: 15px;

}
.searchContainer input{
    border: none !important;

}
.searchContainer i{
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 25px;

}


/* Custom styles */

.section {
  margin-bottom: 50px;
  border-top: 2px solid #333;
  padding-top: 15px;
}

.section-title {
  font-weight: bold;
  margin-bottom: 25px;
}

.actions-container {
  
  border-top: 1px solid #333;
  padding-top: 15px;
}

.action-buttons-wrapper {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.searchContainer {
  display: block;
  width: 100%;
  border: 1px solid #333;
  margin-bottom: 15px;
  margin-top: 15px;
}

  .searchContainer input {
    border: none !important;
    }

  .searchContainer i {
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 25px;
  }

.form-container{

}
